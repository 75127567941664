<template>
  <v-row>
    <v-col>
      <v-card :loading="loading">
        <v-list three-line>
          <v-subheader>
            Results ({{ total }})
            <v-spacer></v-spacer>
            <v-btn @click="showDetail = false" v-show="showDetail">
              hide detail
            </v-btn>
          </v-subheader>
          <template v-for="(item, index) in results">
            <v-list-item :key="`item - ${index}`" @click="getDetail(item)">
              <v-list-item-avatar>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-html="`Q-${item.id} - ${item.date} จำนวน ${item.total} คิว`"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="subhead(item)"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index" :inset="true"></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-col>
    <v-col v-show="showDetail">
      <queue-detail
        ref="qdetail"
        :queueId="queueIdSelected"
        @queueUpdated="() => {}"
        @queueDeleted="onQueueDeleted"
      />
    </v-col>
  </v-row>
</template>

<script>
import queueDetail from "../../queueManangement/components/eventDetail";
export default {
  props: ["results", "total", "loading"],
  components: { queueDetail },
  data() {
    return {
      showDetail: false,
      queueIdSelected: ""
    };
  },
  watch: {
    loading() {
      this.showDetail = false;
    }
  },

  computed: {},
  methods: {
    subhead(item) {
      const relcolor =
        item.relation == "จองด้วยตัวเอง" ? "red--text" : "blue--text";
      return `จองโดย <span class="text--primary">${item.name}</span> เลข13หลัก <span class="text--primary red--text">${item.cid}</span> <span class="${relcolor}">(${item.relation})</span>`;
    },
    getDetail(item) {
      this.queueIdSelected = item.id;
      this.showDetail = true;
      // this.$refs.qdetail.getQueue(item.id);
    },
    onQueueDeleted() {
      this.showDetail = false;
      this.queueIdSelected = "";
    }
  }
};
</script>

<style></style>
